import React from 'react'
import classes from './styles.module.css'
import { testIds } from 'config/testIds'

export const Summary = ({ diamond = null }) => {
  if (!diamond) return null

  const list = diamond.polished && diamond.polished.grading_certificates || []

  if (list.length === 0) return null

  let reportNumber = '';
  let inscriptionNumber = '';
  let gradingLab = ''

  if (list[list.length - 1].grading_lab)
    gradingLab = list[list.length - 1].grading_lab

  if (list[list.length - 1].grader_report_no)
    reportNumber = list[list.length - 1].grader_report_no

  if (list[list.length - 1].grader_inscription_no)
    inscriptionNumber = list[list.length - 1].grader_inscription_no

  return (
    <div className={classes.inscriptionReportNumbersContainer}>
      {gradingLab && (
        <div className={classes.info} data-test-id={testIds.diamondSelection.gradingLab}>
          <label>GRADING LAB</label> <span>{gradingLab}</span>
        </div>
      )}
      {reportNumber && (
        <div className={classes.info} data-test-id={testIds.diamondSelection.reporNo}>
          <label>REPORT NUMBER</label> <span>{reportNumber}</span>
        </div>
      )}
      {inscriptionNumber && (
        <div className={classes.info} data-test-id={testIds.diamondSelection.inscriptionNumber}>
          <label>INSCRIPTION NUMBER</label> <span>{inscriptionNumber.split(',').join(', ')}</span>
        </div>
      )}
    </div>
  )
}

export default Summary

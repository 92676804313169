import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import classnames from 'classnames'

import { getDiamondAction } from 'store/actions'
import { Search, NotFound, Footer } from 'components'
import { TracrLogoTextIcon } from 'components/svg'
import classes from './styles.module.css'
import { testIds } from 'config/testIds'

const LandingPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [query, setQuery] = useState(null)
  const [notFound, setNotFound] = useState(false)

  const handleSearch = (query) => {
    setQuery(query)
    setNotFound(false)

    dispatch(getDiamondAction(query))
      .then((response) => {
        if (response) {
          navigate(`/search/${encodeURIComponent(query)}`)
        } else {
          setNotFound(true)
        }
      })
      .catch(() => {
        setNotFound(true)
      })
  }

  useEffect(() => { }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.indexPageContainer}>
      <div className={classes.wrapper}>
        <div className={classes.logo}>
          <div className={classes.image}>
            <TracrLogoTextIcon />
          </div>
          <div className={classes.text}>Diamond Search</div>
        </div>

        <div className={classes.search}>
          <div className={classes.searchBox} data-test-id={testIds.landingPage.searchBox}>
            <Search onSubmit={handleSearch} tooltipText={'Search by Tracr ID, Grading Report or Inscription Number.'} />
          </div>
          <div className={classnames(classes.error, { [classes.visible]: notFound })}>
            <NotFound query={query} />
          </div>
        </div>
        <div className={classes.footer}>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default LandingPage

import { Navigate } from 'react-router-dom'
import env from '@beam-australia/react-env'

import PlaceholderPage from 'pages/Placeholder'
import LandingPage from 'pages/Landing'
import ResultsPage from 'pages/Results'
import SharePage from 'pages/Share'
import { DefaultLayout } from 'containers/Layouts'
import localization from 'localization/en.json'

const copy = localization.routes

const isTrue = (val) => {
  return val === true || /^true$/i.test(val)
}

const SEARCH_ENABLED = isTrue(env('SEARCH_ENABLED'))
const SHARE_ENABLED = isTrue(env('SHARE_ENABLED'))

const defaultRoute = '/'

let routes = [
  {
    path: '*',
    element: <Navigate to={defaultRoute} />,
  },
]

if (SEARCH_ENABLED) {
  routes = routes.concat([
    {
      path: '/',
      element: <DefaultLayout />,
      children: [{ title: copy.landing, index: true, element: <LandingPage /> }],
    },
    {
      path: '/search/:query',
      element: <DefaultLayout />,
      children: [{ title: copy.landing, index: true, element: <ResultsPage /> }],
    },
    {
      path: '/search/:query/:diamondId',
      element: <DefaultLayout />,
      children: [{ title: copy.landing, index: true, element: <ResultsPage /> }],
    },
  ])
} else {
  routes = routes.concat([
    {
      path: '/',
      element: <DefaultLayout />,
      children: [{ title: copy.landing, index: true, element: <PlaceholderPage /> }],
    },
  ])
}

if (SHARE_ENABLED) {
  routes = routes.concat([
    {
      path: '/share/:id/:pos',
      element: <DefaultLayout />,
      children: [{ title: copy.landing, index: true, element: <SharePage /> }],
    },
    {
      path: '/share/:id',
      element: <DefaultLayout />,
      children: [{ title: copy.landing, index: true, element: <SharePage /> }],
    },
    {
      path: '/share',
      element: <DefaultLayout />,
      children: [{ title: copy.landing, index: true, element: <SharePage /> }],
    },
  ])
}

export default routes

import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { selectDiamond } from 'store/selectors'
import { getDiamondAction } from 'store/actions'
import { Searchbar, Diamond, NoResults, Disclaimer, Footer, DiamondList } from 'components'
import { testIds } from 'config/testIds'

import classes from './styles.module.css'

const ResultsPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch()
  const diamonds = useSelector(selectDiamond)
  const [isNotFound, setIsNotFound] = useState(false)
  const query = params.query
  const diamondId = params.diamondId || ''

  const handleSearch = (query) => {
    navigate(`/search/${encodeURIComponent(query)}`)
  }

  const onSelect = diamondId => {
    navigate(`/search/${encodeURIComponent(query)}/${encodeURIComponent(diamondId)}`)
  }

  let selectedDiamond = null;

  if (diamonds && diamonds.length === 1) {
    selectedDiamond = diamonds[0];
  }

  if (diamonds && diamondId) {
    const filteredDiamond = diamonds.filter(diamond => diamond.current.diamond_id === diamondId)
    if (filteredDiamond.length > 0) {
      selectedDiamond = filteredDiamond[0]
    }
  }

  const searchDIamond = (query) => {
    setIsNotFound(false)

    dispatch(getDiamondAction(query))
      .then((response) => {
        if (!response) {
          setIsNotFound(true)
        }
      })
      .catch(() => {
        setIsNotFound(true)
      })
  }

  useEffect(() => {
    searchDIamond(query)
  }, [query]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.resultsPageContainer}>
      <div className={classes.wrapper}>
        <div className={classes.header} data-test-id={testIds.resultPage.searchBar}>
          <Searchbar value={query} withSearch onSubmit={handleSearch} tooltipText={'Search by Tracr ID, Grading Report or Inscription Number.'} />
        </div>
        {isNotFound ? (
          <div className={classes.noResults}>
            <NoResults query={query} />
          </div>
        ) : null}
        {selectedDiamond ? (
          <div className={classes.viewer}>
            <div className={classes.positioner}>
              <div className={classes.diamond}>
                <Diamond diamond={selectedDiamond} />
              </div>
            </div>
          </div>
        ) : null}
        {diamonds && diamonds.length > 1 && !selectedDiamond ? (
          <div className={classes.viewer}>
            <div className={classes.positioner}>
              <div className={classes.diamond}>
                <DiamondList diamonds={diamonds} query={query} onSelect={onSelect} />
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.footer}>
          <div className={classes.disclaimer}>
            <Disclaimer />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default ResultsPage

import React, { useState, useEffect } from 'react'
import { getPriorityDataPoints } from 'utils/diamond'
import { Provenance } from '@tracr/shared-components'
import Body from './Body'
import classes from './styles.module.css'
import { testIds } from 'config/testIds'



export const Card = ({ diamond, onSelect }) => {
  const [diamondContent, setDiamondContent] = useState(diamond)
  const getHeading = () => {
    if (!diamondContent) return ''
    return `, ${getPriorityDataPoints(diamondContent.current, diamondContent.current.lifecycle_state, false).join(', ')}`
  }
  useEffect(() => {
    if (!diamond) return

    setDiamondContent(diamond)
  }, [diamond]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!diamondContent) return null


  return (
    <div className={classes.cardContainer}>
      <div className={classes.wrapper}>
        <div className={classes.positioner}>
          <div className={classes.heading}>
            <span>
            <Provenance provenance={diamondContent.current.provenance} />
             {getHeading()}
            </span>
          </div>
          <Body diamond={diamondContent.current} />
          <div
            className={classes.footer}
            onClick={() => onSelect(diamondContent.current.diamond_id)
            }
            data-test-id={testIds.diamondSelection.viewDiamond}
          >
            View Diamond
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card

import React from 'react'
import classes from './styles.module.css'
import Card from './Card'
import { testIds } from 'config/testIds'
export const DiamondList = ({ diamonds, query, onSelect }) => {
  return (
    <div className={classes.diamondListContainer}>
      <div className={classes.labelContainer} data-test-id={testIds.diamondSelection.header}>
        <label className={classes.label}>Multiple diamonds found for “{query}”</label>
        <label className={classes.label}>Please select the diamond you wish to view.</label>
      </div>
      <div>
        <div className={classes.listContainer}>
          {diamonds.map((diamond) => (
            <div className={classes.listItem} key={diamond.current.id}>
              <Card diamond={diamond} onSelect={onSelect} />
            </div>
          ))
          }
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import Thumbnail from './Thumbnail'
import Summary from './Summary'
import classes from './styles.module.css'

export const Body = ({ diamond }) => {
  return (
    <div className={classes.bodyContainer}>
      <div className={classes.wrapper}>
        <div className={classes.tracrId}>
          <div className={classes.heading}>Tracr ID</div>
          <div className={classes.id}>
            {diamond.diamond_id}
          </div>
        </div>
        <div className={classes.imageAndCopy}>
          <div className={classes.headerImageContainer} >
            <div className={classes.headerImage}>
              <Thumbnail diamond={diamond} />
            </div>
          </div>
          <Summary diamond={diamond} />
        </div>
      </div>
    </div>
  )
}
export default Body

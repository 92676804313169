import React from 'react'

import classes from './styles.module.css'

export const NotFound = ({ query = '' }) => {
  return (
    <div className={classes.notFoundContainer}>
      <div className={classes.wrapper}>
            <div className={classes.text}>No diamond was found for ‘{query}’. This might be because it does not exist or because it is locked.</div>
            <div className={classes.text}>Please check the diamond’s locked status with your supplier.</div>
      </div>
    </div>
  )
}

export default NotFound
